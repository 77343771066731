import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Route, Routes, useLocation, } from 'react-router-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
// import Navigation from './containers/Navigation';
import Navigation2 from './containers/Navigation2';
import Footer from './containers/Footer';
import ContactUs from './containers/ContactUs.js';
import Error404 from './containers/Error404.js';
import SearchLanding from './containers/SearchLanding.js';

import Home from './containers/Homepage/Home.js';
import WhoWeAreLanding from './containers/WhoWeAre/WhoWeAre.js';
import WhatWeDoLanding from './containers/WhatWeDo/WhatWeDoLanding.js';
import WhatWeDoInner from './containers/WhatWeDo/WhatWeDoInner.js';
// import WhereWeWork from './containers/WhereWeWork/WhereWeWork.js';
import NewsLanding from './containers/News/NewsLanding.js';
import NewsInner from './containers/News/NewsInner.js';

import ResourcesLanding from './containers/Resources/ResourcesLanding.js';
import ResourcesInner from './containers/Resources/ResourcesInner.js';

import WhereWeWorkMap from './containers/WhereWeWork/WhereWeWorkMap.js';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Navigation2/>
        <ScrollToTop/>
          <Routes>

            {/* <Route exact path="/where-we-work-map" element={<WhereWeWorkMap/>} /> */}
            <Route exact path="/where-we-work" element={<WhereWeWorkMap/>} />
            <Route exact path="/what-we-do" element={<WhatWeDoLanding/>} />
            <Route exact path="/what-we-do/:nodeid" element={<WhatWeDoInner/>} />
            <Route exact path="/contact" element={<ContactUs/>} />
            <Route exact path="/search" element={<SearchLanding/>} />
            <Route exact path="/news" element={<NewsLanding/>} />
            <Route exact path="/news/:nodeid" element={<NewsInner/>} />
            <Route exact path="/resources/:nodeid" element={<ResourcesInner/>} />
            <Route exact path="/resources" element={<ResourcesLanding/>} />
            <Route exact path="/who-we-are" element={<WhoWeAreLanding/>} />
            <Route exact path="/" element={<Home/>} />
            <Route path="*" element={<Error404/>} />

          </Routes>
      <Footer/>
    </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
